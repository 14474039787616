import dateTimeFormatter from '@/models/common/date-time-formatter';
import VuePlotly from '@/components/VuePlotly';

export default {
  name: 'witness-histogram',
  components: {
    VuePlotly
  },
  props: {
    card_title_base: String,
    titleOverride: String,
    data: Array,
    loading: Boolean,
    metadata: Object,
    autoResize: Boolean,
    nameKey: String,
    modeKey: String,
    wpname: String
  },
  data () {
    return {
      showOutliers: false,
      outliers: 0,
      selected: [],
      generic_chart_layout: {
        margin: {
          l: 55,
          r: 45,
          b: 35,
          t: 15,
          pad: 5
        },
        showlegend: false,
        xaxis: {
          tickformat: ',d',
          automargin: false,
          title: {
            text: 'Minutes',
            font: {
              family: 'Courier New, monospace',
              size: 14,
              color: '#7f7f7f'
            }
          }
        },
        yaxis: {
          domain: [0, 1],
          tickformat: ',d',
          title: {
            text: 'Count',
            font: {
              family: 'Courier New, monospace',
              size: 14,
              color: '#7f7f7f'
            }
          }
        },
        hovermode: 'x'
      },
      generic_chart_options: {
        responsive: true,
        displayModeBar: 'hover',
        showSendToCloud: false,
        showEditInChartStudio: false,
        showLink: false,
        showTips: false,
        displaylogo: false,
        modeBarButtonsToRemove: ['toImage']
      }
    };
  },
  computed: {
    noData: function () {
      if(this.metadata?.histogram?.binVal?.length || this.loading) {
        return false;
      }
      return true;
    },
    histData: function () {
      let template = '';
      let filteredData = {
        xs: [],
        binNum: [],
        binVal: [],
        count: []
      };

      if (this.metadata.histogram.binVal?.length && !this.showOutliers) {
        // var deviationInMinutesForOutlier = ((this.metadata.histogram.avg_average_duration / 60 > 60) ? (this.metadata.histogram.avg_average_duration / 60)*1.5 : 60) ;
        // var deviationInMinutesForOutlier = ((this.metadata.histogram.avg_average_duration > 3600) ? )
        var deviationInMinutesForOutlier = ((this.metadata.histogram.avg_average_duration > 60) ? this.metadata.histogram.avg_average_duration : 60) * 2;
        // console.log('deviation: ' + String(deviationInMinutesForOutlier));
        this.outliers = 0;
        this.metadata.histogram.binVal.forEach(function (element, i) {
          // No longer have the average_duration to compare to avg_average_duration, show values that are less than 1.5 * the avg_average_duration
          if (this.metadata.histogram.binVal[i] < deviationInMinutesForOutlier) {
            // console.log('in: ' + String(this.metadata.histogram.binVal[i]));
            filteredData.count.push(this.metadata.histogram.count[i]);
            filteredData.binNum.push(this.metadata.histogram.binNum[i]);
            filteredData.binVal.push(this.metadata.histogram.binVal[i]);
          } else {
            // console.log('out: ' + String(this.metadata.histogram.binVal[i]));
            this.outliers += 1;
          }
        }, this);
      } else {
        filteredData = this.metadata.histogram;
      }

      let formattedXs = [];
      let xScope = 'Minutes';

      if (this.modeKey === 'count') {
        filteredData.binVal.forEach(element => {
          if (Math.max(...filteredData.binVal) > 2880) {
            formattedXs.push(Math.round(element / 1440));
            xScope = 'Days';
          } else if (Math.max(...filteredData.binVal) > 120) {
            formattedXs.push(Math.round(element / 60));
            xScope = 'Hours';
          } else {
            formattedXs.push(element);
            xScope = 'Minutes';
          }
        });
      } else {
        formattedXs = filteredData.binVal;
      }

      let chartData = [];
      chartData = filteredData.count;
      template = '%{y} events<extra></extra>';

      const temp = [
        {
          type: 'bar',
          hovertemplate: template,
          x: formattedXs,
          y: chartData,
          opacity: 0.8,
          marker: {
            color: 'rgb(150, 61, 179)'
          },
          transforms: [
            {
              type: 'aggregate',
              groups: formattedXs,
              aggregations: [
                {
                  target: 'y',
                  func: 'sum'
                }
              ]
            }
          ]
        }
      ];

      const output = {
        data: temp,
        layout: {}
      };

      const layout = JSON.parse(JSON.stringify(this.generic_chart_layout));

      if (this.modeKey === 'count') {
        layout.yaxis.title.text = 'Event count';
        layout.showlegend = false;
      } else {
        layout.yaxis.title.text = 'Count';
        layout.showlegend = false;
        layout.grid = {
          rows: 2,
          columns: 1,
          subplots: [['xy2'], ['xy']],
          roworder: 'bottom to top'
        };
      }

      if (this.$vuetify.breakpoint.xsAndDown) {
        layout.yaxis.title.text = '';
        layout.xaxis.title.text = '';
      } else {
        layout.xaxis.title.text = xScope;
      }

      output.layout = layout;

      return output;
    },
    cardTitle: function () {
      let title = '';
      if (this.modeKey === 'count') {
        title = 'Average duration by event count histogram';
      } else if (this.modeKey === 'average_duration') {
        title = 'Event Duration Histogram';
      } else {
        title = 'Histogram of total time spent by ' + this.card_title_base;
      }

      if (this.titleOverride) {
        title = this.titleOverride;
      }

      return title;
    },
    histLayout: function () {
      const layout = this.generic_chart_layout;

      if (this.modeKey === 'count') {
        layout.yaxis.title.text = 'Event count';
        layout.showlegend = false;
      } else {
        layout.yaxis.title.text = 'Count';
        layout.showlegend = false;
        layout.grid = {
          rows: 2,
          columns: 1,
          subplots: [['xy2'], ['xy']],
          roworder: 'bottom to top'
        };
      }

      if (this.$vuetify.breakpoint.xsAndDown) {
        layout.yaxis.title.text = '';
        layout.xaxis.title.text = '';
      } else {
        layout.xaxis.title.text = 'Minutes';
      }

      return layout;
    }
  },
  methods: {
    formatDT: dateTimeFormatter.deconstructDateTimeSeconds,
    hist_hover: function (event) {
      if (this.modeKey !== 'count') {
        this.$refs.hist_ref.hover(
          [
            { curveNumber: 0, pointNumber: event.points[0].pointNumber },
            { curveNumber: 1, pointNumber: event.points[0].pointNumber }
          ],
          ['xy', 'xy2']
        );
      }
    },
    convertToCSV: function (array, headerRow = null) {
      if (!headerRow) {
        // Create the header row dynamically from object keys
        headerRow = Object.keys(array[0]);
      }

      // Loop over the array and output an array in headerRow order for each object
      let csv = array.map(row => {
        return headerRow.map(name => {
          if (typeof row[name] === 'string' || row[name] instanceof String) {
            return '"' + row[name] + '"';
          }
          return row[name];
        });
      });

      // Put the header row back as the first entry in the array
      csv = [headerRow].concat(csv);

      // Generate and return CSV data from the 2d array
      return csv.map(it => {
        return Object.values(it).toString();
      }).join('\n');
    },
    dl_csv: function () {
      const outData = [];

      let tempRow = {};

      this.metadata.histogram.binNum.forEach((item, index) => {
        tempRow = {};

        tempRow.bin_name = this.metadata.histogram.binNum[index];

        if (this.metadata.histogram.average_duration) {
          tempRow.average_duration = this.metadata.histogram.average_duration[index];
        }
        if (this.metadata.histogram.count) {
          tempRow.count = this.metadata.histogram.count[index];
        }
        if (this.metadata.histogram.total_time) {
          tempRow.total_time = this.metadata.histogram.total_time[index];
        }

        outData.push(tempRow);
      });

      const csv = this.convertToCSV(outData);

      if (window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob(['\ufeff' + decodeURIComponent(encodeURI(csv))], {
          type: 'text/csv;charset=utf-8;'
        });
        navigator.msSaveBlob(blob, 'histogram.csv');
      } else {
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + '\ufeff' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'histogram.csv';
        hiddenElement.click();
        hiddenElement.remove();
      }
    }
  },
  watch: {
  }
};