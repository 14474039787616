import apiEndpoints from '@/models/common/api-endpoints';
import generalFunctions from '@/models/common/general-functions';
import witnessDateFilter from '@/components/WitnessDateFilter';
import witnessOptionSelector from '@/components/WitnessOptionSelector';
import tipsAndTricks from '@/components/TipsAndTricks';
import { mapGetters } from 'vuex';

export default {
  name: 'IntervalFilter',
  props: {
    intervalsExplorerIsOpen: Boolean
  },
  components: {
    witnessDateFilter,
    witnessOptionSelector,
    tipsAndTricks
  },
  data: function () {
    return {
      startDate: undefined,
      endDate: undefined,
      
      // sub component data
      clinicData: null,
      mapClinicIdToData: new Map(),
      clinicOptions: new Array(),
      selectedClinicOptionIds: new Array(),
      
      intervalData: null,
      mapIntervalIdToData: new Map(),
      mapIntervalIdToOptionId: new Map(),
      intervalOptions: new Array(),
      intervalPreselectedOptionIds: new Array(),
      selectedIntervalOptionIds: new Array(),
      preselectedIntervalId: null
    };
  },
  mounted() {
    this.retrieveAllClinics();
  },
  computed: {
    ...mapGetters({
      allClinics: 'allClinics'
    })
  },
  methods: {
    //
    // External
    //
    reloadIntervals: function (clinicId, deviceId, intervalId) {
      // console.log('IF-CM: reloadIntervals', clinicId, deviceId, intervalId);
      
      // set this to be checked on load of data - if null it'll auto-select the first
      this.preselectedIntervalId = intervalId;
      
      // now reload
      this.retrieveIntervalsForClinic(clinicId, deviceId);
    },
    //
    // Interval
    //
    updateDateRange: function (startDate, endDate) {
      // console.log('IF-CM: updateDateRange');
      
      this.startDate = startDate;
      this.endDate = endDate;
      
      this.emitDateRangeChanged();
    },

    requestDateJump: function (startDate, endDate) {
      // console.log('IF-CM: requestDateJump');
      
      this.updateDateRange(startDate, endDate);
    },

    getSelectedClinicGuids: function () {
      let selectedClinicGuids = [];
      for (let ix = 0; ix < this.selectedClinicOptionIds.length; ix++) {
        let clinicGuid = this.clinicOptions[this.selectedClinicOptionIds[ix]].objId;
        selectedClinicGuids.push(clinicGuid);
      }
      return selectedClinicGuids;
    },

  
    clickExplore: function () {
      this.$emit('clickExplore');
    },
    retrieveAllClinics: async function () {
      // console.log('IF-CM: retrieveAllClinics');
      
      const response = await apiEndpoints.getAllClinics();
      
      if (response.result) {
        this.clinicData = response.data;
      } else {
        this.clinicData = null;
      }
    },
    retrieveIntervalsForClinic: async function (clinicId, deviceId) {
      // console.log('IF-CM: retrieveIntervalsForClinic', clinicId, deviceId);
      
      const response = await apiEndpoints.getWitnessIntervalConfigsForClinic(clinicId, deviceId);
      
      if (response.result) {
        this.intervalData = response.data;
      } else {
        this.intervalData = null;
      }
    },
    updateClinicSelector: function () {
      if (this.mapClinicIdToData != null)
      {
        // Build representation of options, inc groupings, in abstracted format expected by option-selector component
        let nextId = 0;
        
        for (const clinicData of this.mapClinicIdToData.values()) {
          let clinicOption = { id: nextId++, groupId: null, label: clinicData.clinicname, objId: clinicData.clinicid };
          
          this.clinicOptions.push(clinicOption);
        }        
      }
    },
    updateIntervalSelector: function () {
      // console.log('IF-CM: updateIntervalSelector');
      
      while (this.intervalOptions.length > 0) {
        this.intervalOptions.pop();
      }
      this.mapIntervalIdToOptionId.clear();
      
      if (this.mapIntervalIdToData != null) {
        // Build representation of options, inc groupings, in abstracted format expected by option-selector component
        let nextId = 0;
        
        for (const intervalData of this.mapIntervalIdToData.values()) {
          // The Option array
          let intervalOption = { id: nextId, groupId: null, label: intervalData.name, objId: intervalData.intervalID };
          this.intervalOptions.push(intervalOption);
          
          // And the reverse map from Id to OptionId
          this.mapIntervalIdToOptionId.set(intervalData.intervalID, nextId);
          
          nextId++;
        }        
      }
    },
    clinicSelectionChange: function (data) {
      this.selectedClinicOptionIds = data;
      
      // Reset Intervals
      this.selectedIntervalOptionIds = [];
      this.preselectedIntervalId = null;
      
      if (this.selectedClinicOptionIds.length == 1) {
        
        let clinicOptionId = this.selectedClinicOptionIds[0];
        let clinicOption = this.clinicOptions[clinicOptionId];
        let clinicData = this.mapClinicIdToData.get(clinicOption.objId);
        let clinicId = clinicData.clinicid;
        let deviceId = clinicData.witnessid;
        
        this.retrieveIntervalsForClinic(clinicId, deviceId);

        let clinic = this.allClinics.find(clinic => { return clinic.clinicId == clinicId; });
        if (clinic) {
          this.$store.commit('setCurrentClinic', clinic);
        }
      }
      else {
        console.error('IF-CM: clinicSelectionChange - IMPOSSIBLE');
      }
    },
    intervalSelectionChange: function (data) {
      this.selectedIntervalOptionIds = data;
      
      if (this.selectedIntervalOptionIds.length == 1) {
        this.emitSelectionChanged();
      }
      else {
        console.error('IF-CM: intervalSelectionChange - IMPOSSIBLE');
      }
    },
    emitSelectionChanged: function () {
      let clinicOptionId = this.selectedClinicOptionIds[0];
      let clinicOption = this.clinicOptions[clinicOptionId];
      
      if (clinicOption) {
        let clinicData = this.mapClinicIdToData.get(clinicOption.objId);
        let clinicId = clinicData.clinicid;
        
        // NOTE: API call from page needs the deviceId too
        let deviceId = clinicData.witnessid;
        
        let intervalData = null;
        
        if (this.selectedIntervalOptionIds.length > 0) {
          let intervalOptionId = this.selectedIntervalOptionIds[0];
          let intervalOption = this.intervalOptions[intervalOptionId];
  
          intervalData = this.mapIntervalIdToData.get(intervalOption.objId);
        }
        
        this.$emit('selection-changed', clinicId, deviceId, intervalData);
      }
    },
    emitDateRangeChanged: function () {
      // console.log('IF-CM: emitDateRangeChanged');
      
      let dateJump = this.$refs.dateFilter.jumpAmount;
      let datePeriod = this.$refs.dateFilter.jumpPeriod;
      
      this.$emit('date-range-changed', this.startDate, this.endDate, dateJump, datePeriod);
    },
    compareClinicByName: function (clinicA, clinicB) {
      if (clinicA.clinicname.toLowerCase() < clinicB.clinicname.toLowerCase()) {
        return -1;
      }
      if (clinicA.clinicname.toLowerCase() > clinicB.clinicname.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    updateClinicInfo: function () {
      this.mapClinicIdToData.clear();
      
      // clone instead of ref
      let tmp = generalFunctions.deepCopy(this.clinicData);
      if (tmp) {
        // sort by clinicName
        tmp.sort(this.compareClinicByName);
  
        // build map in that order
        tmp.forEach(t => {
          this.mapClinicIdToData.set(t.clinicid, t);
        });
      }
    },
    updateIntervalInfo: function () {
      this.mapIntervalIdToData.clear();
      
      if (this.intervalData) {
        this.intervalData.forEach(t => {
          this.mapIntervalIdToData.set(t.intervalID, t);
        });
      }
    }
  },
  watch: {
    clinicData: function (newVal, oldVal) {
      // console.log('IF-CM: watch - clinicData');
        
      this.updateClinicInfo();
      this.updateClinicSelector();

      // Pre-select the first Clinic after loading data
      if (this.clinicOptions.length > 0) {
        this.$refs.clinicSelector.selectOptions([0]);
      }
    },
    intervalData: function (newVal, oldVal) {
      // console.log('IF-CM: watch - intervalData');
        
      this.updateIntervalInfo();
      this.updateIntervalSelector();

      // Pre-select specified Interval
      if (this.preselectedIntervalId) {
        // Lookup Interval OptionId from Id
        let optionId = this.mapIntervalIdToOptionId.get(this.preselectedIntervalId);
        
        this.intervalPreselectedOptionIds = [optionId];
        this.preselectedIntervalId = null;
      }
      // Pre-select the first Interval after loading data
      else if (this.intervalOptions.length > 0) {
        this.intervalPreselectedOptionIds = [0];
      }
      // Else open the Explorer
      else {
        this.emitSelectionChanged();
      }
    }
  }
};
