import dateTimeFormatter from '@/models/common/date-time-formatter';
import VuePlotly from '@/components/VuePlotly';

import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export default {
  name: 'witness-historical',
  components: {
    VuePlotly
  },
  props: {
    card_title_base: String,
    titleOverride: String,
    nounOverride: String,
    xAxisTitlePeriod: {
      type: String,
      default: undefined
    },
    csv_dl_color_override: {
      type: String,
      default: undefined
    },
    data: Array,
    loading: Boolean,
    metadata: Object,
    autoResize: Boolean,
    nameKey: String,
    modeKey: String,
    wpname: String
  },
  data () {
    return {
      selected: [],
      generic_chart_layout: {
        margin: {
          l: 55,
          r: 45,
          b: 68,
          t: 25,
          pad: 0
        },
        showlegend: false,
        legend: {
          orientation: 'h',
          x: 0.1,
          y: 1.2
        },
        xaxis: {
          type: 'category',
          showticklabels: true,
          tickangle: '30',
          automargin: false,
          title: {
            text: this.xAxisTitlePeriod,
            font: {
              family: 'Courier New, monospace',
              size: 14,
              color: '#7f7f7f'
            }
          }
        },
        yaxis: {
          domain: [0.4, 1],
          title: {
            text: 'Count',
            font: {
              family: 'Courier New, monospace',
              size: 14,
              color: '#7f7f7f'
            }
          }
        },
        yaxis2: {
          domain: [0, 0.3],
          tickformat: ',d',
          title: {
            text: 'Count',
            font: {
              family: 'Courier New, monospace',
              size: 14,
              color: '#7f7f7f'
            }
          }
        },
        hovermode: 'x'
      },
      generic_chart_options: {
        responsive: true,
        displayModeBar: 'hover',
        showSendToCloud: false,
        showEditInChartStudio: false,
        showLink: false,
        showTips: false,
        displaylogo: false,
        modeBarButtonsToRemove: ['toImage']
      }
    };
  },
  computed: {
    noData: function () {
      // Early out
      if (this.loading) { return false; }

      // If count mode
      if (this.modeKey == 'count' && !this.metadata.historical.count?.every(item => item === 0)) {
        return false;
      }
      if(this.modeKey == 'average_duration' && !this.metadata.historical.average_duration?.every(item => item === 0)) {
        return false;
      }
      if(this.modeKey == 'total_time' && !this.metadata.historical.total_time?.every(item => item === 0)) {
        return false;
      }
      return true;
    },
    histData: function () {
      // var formattedDates = []
      const formattedAverages = [];
      const formattedTotals = [];
      let template = '';
      let dates = [];

      let newHist = { average_duration: [], count: [], count_with_duration: [], dates: [], total_time: [] };
      if (this.xStartDate && this.xEndDate) {
        // do some prep
        const startDate = dayjs.utc(this.xStartDate.substr(0, 7) + '-01T00:00:00.000Z');
        const endDate = dayjs.utc(this.xEndDate.substr(0, 7) + '-01T00:00:00.000Z');
        let tempDate = dayjs.utc(startDate);
        dates = [];

        while (tempDate < endDate) {
          tempDate = tempDate.add(1, 'months');
          dates.push(tempDate.toISOString());
        }

        // console.log(dates) // date gen is working

        for (let i = 0; i < dates.length; i++) {
          // add the new-gen date to the object
          newHist.dates.push(dates[i]);

          // Test for different date formats from the API (it's inconsistent)
          if (this.metadata && this.metadata.historical.dates.length > 0 && this.metadata.historical.dates[0].length === 23) {
            dates = dates.map(date => {
              return date.substring(0, 23);
            });
          } else if (this.metadata.historical.dates.length > 0) {
            dates = dates.map(date => {
              return date.substring(0, 19);
            });
          }

          // console.log(newHist)

          // Check if a data point exists in the old object for this date
          if (this.metadata.historical.dates.includes(dates[i])) {
            const j = this.metadata.historical.dates.indexOf(dates[i]);
            if (this.metadata.historical.average_duration) {
              newHist.average_duration.push(this.metadata.historical.average_duration[j]);
            }
            if (this.metadata.historical.count) {
              newHist.count.push(this.metadata.historical.count[j]);
            }
            if (this.metadata.historical.count_with_duration) {
              newHist.count_with_duration.push(this.metadata.historical.count_with_duration[j]);
            }
            if (this.metadata.historical.total_time) {
              newHist.total_time.push(this.metadata.historical.total_time[j]);
            }
          } else {
            newHist.average_duration.push(0);
            newHist.count.push(0);
            newHist.count_with_duration.push(0);
            newHist.total_time.push(0);
          }
        }
      } else {
        dates = this.metadata.historical.dates;
        newHist = this.metadata.historical;
      }
      // console.log(newHist)

      dates = dates.map(date => {
        return date.substr(0, 10);
      });

      if (newHist.average_duration && Array.isArray(newHist.average_duration) && newHist.average_duration.length > 0) {
        newHist.average_duration.forEach(element => {
          formattedAverages.push(this.formatDT(element));
        });
      }

      if (newHist.total_time) {
        newHist.total_time.forEach(element => {
          formattedTotals.push(this.formatDT(element));
        });
      }

      let chartData = [];
      const countData = newHist.count_with_duration;

      let formattedYs = [];
      let yScope = 'Event count';

      if (this.modeKey !== 'count') {
        newHist[this.modeKey].forEach(element => {
          if (Math.max(...newHist[this.modeKey]) > 172800) {
            formattedYs.push(element / 86400);
            yScope = 'Days';
          } else if (Math.max(...newHist[this.modeKey]) > 7200) {
            formattedYs.push(element / 3600);
            yScope = 'Hours';
          } else if (Math.max(...newHist[this.modeKey]) > 120) {
            formattedYs.push(element / 60);
            yScope = 'Minutes';
          } else {
            formattedYs.push(element);
            yScope = 'Seconds';
          }

          // if (Math.max(...this.metadata.historical[this.modeKey]) > 120) {
          //   formattedYs.push(element / 60)
          // } else if (Math.max(...this.metadata.historical[this.modeKey]) > 7200) {
          //   formattedYs.push(element / 3600)
          // } else if (Math.max(...this.metadata.historical[this.modeKey]) > 172800) {
          //   formattedYs.push(element / 86400)
          // } else {
          //   formattedYs.push(element)
          // }
        });
      } else {
        formattedYs = newHist[this.modeKey];
      }

      if (this.modeKey === 'count') {
        chartData = newHist.count;
        template = '%{y} events<extra></extra>';
        if (this.nounOverride) {
          template = '%{y} ' + this.nounOverride + '<extra></extra>';
        }
      } else if (this.modeKey === 'average_duration') {
        chartData = formattedAverages;
        template = '<b>%{hovertext}</b><extra></extra>';
      } else {
        chartData = formattedTotals;
        // template = '<b>%{text}</b><br>or<br>%{y} seconds<extra></extra>'
        template = '<b>%{hovertext}</b><extra></extra>';
      }

      const temp = [
        {
          name: '',
          type: 'bar',
          hovertext: chartData,
          hovertemplate: template,
          customdata: countData,
          x: dates,
          // y: this.metadata.historical[this.modeKey],
          y: formattedYs,
          // y: y,
          opacity: 0.7
        }
      ];

      if (this.modeKey !== 'count') {
        temp.push(
          {
            name: 'Count with duration data',
            // hoverinfo: 'y',
            hovertemplate: 'Count: %{y}<extra></extra>',
            x: dates,
            y: newHist.count_with_duration,
            yaxis: 'y2',
            type: 'bar',
            opacity: 0.7
          }
        );
      }

      if (this.modeKey === 'count') {
        // return [temp[0]]
      } else if (this.modeKey === 'average_duration') {
        temp[0].name = 'Average duration';
      } else {
        temp[0].name = 'Total time';
      }

      // console.log(temp)
      // return temp
      const output = {
        data: temp,
        layout: {}
      };

      const layout = JSON.parse(JSON.stringify(this.generic_chart_layout)); // cheap deep copy equivalent

      if (this.modeKey === 'count') {
        layout.yaxis.title.text = 'Event count';
        layout.yaxis.domain = [0, 1];
        delete layout.yaxis2;
        layout.showlegend = false;
      } else {
        layout.yaxis.title.text = yScope;
        layout.showlegend = true;
        layout.grid = {
          rows: 2,
          columns: 1,
          subplots: [['xy2'], ['xy']],
          roworder: 'bottom to top'
        };
      }

      if (this.$vuetify.breakpoint.xsAndDown) {
        layout.yaxis.title.text = '';
        layout.xaxis.title.text = '';
      } else {
        const basicText = this.xAxisTitlePeriod + ' beginning';
        layout.xaxis.title.text = basicText.charAt(0).toUpperCase() + basicText.slice(1);
      }

      output.layout = layout;

      return output;
    },
    cardTitle: function () {
      let title = '';
      if (this.modeKey === 'count') {
        // title = 'Historical number of procedures by ' + this.card_title_base
        title = 'Historical Event Count';
      } else if (this.modeKey === 'average_duration') {
        title = 'Historical Average Event Duration';
      } else {
        title = 'Historical Total Event Duration';
      }

      if (this.titleOverride) {
        title = this.titleOverride;
      }

      return title;
    },
    histLayout: function () {
      const layout = JSON.parse(JSON.stringify(this.generic_chart_layout));

      if (this.modeKey === 'count') {
        layout.yaxis.title.text = 'Event count';
        layout.showlegend = false;
      } else {
        layout.yaxis.title.text = 'Seconds';
        layout.showlegend = true;
        layout.grid = {
          rows: 2,
          columns: 1,
          subplots: [['xy2'], ['xy']],
          roworder: 'bottom to top'
        };
      }

      if (this.$vuetify.breakpoint.mdAndDown) {
        layout.yaxis.title.text = '';
        layout.xaxis.title.text = '';
      } else {
        layout.xaxis.title.text = 'Month';
      }
      return layout;
    }
  },
  methods: {
    formatDT: dateTimeFormatter.deconstructDateTimeSeconds,
    hist_hover: function (event) {
      // console.log(event)
      if (this.modeKey !== 'count') {
        this.$refs.hist_ref.hover(
          [
            { curveNumber: 0, pointNumber: event.points[0].pointNumber },
            { curveNumber: 1, pointNumber: event.points[0].pointNumber }
          ],
          ['xy', 'xy2']
        );
      }
    },
    convertToCSV: function (array, headerRow = null) {
      if (!headerRow) {
        // Create the header row dynamically from object keys
        headerRow = Object.keys(array[0]);
      }

      // Loop over the array and output an array in headerRow order for each object
      let csv = array.map(row => {
        return headerRow.map(name => {
          if (typeof row[name] === 'string' || row[name] instanceof String) {
            return '"' + row[name] + '"';
          }
          return row[name];
        });
      });

      // Put the header row back as the first entry in the array
      csv = [headerRow].concat(csv);

      // Generate and return CSV data from the 2d array
      return csv.map(it => {
        return Object.values(it).toString();
      }).join('\n');
    },
    dl_csv: function () {
      // var data = JSON.parse(JSON.stringify(this.metadata.historical)) // cheap deep copy equivalent
      const outData = [];

      // console.log(data)
      let tempRow = {};

      this.metadata.historical.dates.forEach((item, index) => {
        tempRow = {};

        tempRow.dates = this.metadata.historical.dates[index];

        if (this.metadata.historical.average_duration) {
          tempRow.average_duration = this.metadata.historical.average_duration[index];
        }
        if (this.metadata.historical.count) {
          tempRow.count = this.metadata.historical.count[index];
        }
        if (this.metadata.historical.count_with_duration) {
          tempRow.count_with_duration = this.metadata.historical.count_with_duration[index];
        }
        if (this.metadata.historical.total_time) {
          tempRow.total_time = this.metadata.historical.total_time[index];
        }

        outData.push(tempRow);
        // outData.push({
        //   'dates': this.metadata.historical.dates[index],
        //   'average_duration': this.metadata.historical.average_duration[index],
        //   'count': this.metadata.historical.count[index],
        //   'count_with_duration': this.metadata.historical.count_with_duration[index],
        //   'total_time': this.metadata.historical.total_time[index]
        // })
      });

      // function ConvertToCSV (arr) {
      //   // Construct the header
      //   const array = [Object.keys(arr[0])].concat(arr)

      //   // Construct the CSV
      //   return array.map(it => {
      //     return Object.values(it).toString()
      //   }).join('\n')
      // }

      const csv = this.convertToCSV(outData);

      if (window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob(['\ufeff' + decodeURIComponent(encodeURI(csv))], {
          type: 'text/csv;charset=utf-8;'
        });
        navigator.msSaveBlob(blob, 'historical.csv');
      } else {
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + '\ufeff' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'historical.csv';
        hiddenElement.click();
        hiddenElement.remove();
      }
    }
  },
  watch: {
    // metadata: function () {}
    // 'metadata': function (newVal, oldVal) {
    //   this.internalMetadata = newVal
    // }
  }
};