
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export default {
  name: 'WitnessDatePicker',
  props: {
    datePickerValue: {
      type: String,
      default: dayjs.utc().format('YYYY-MM')
    },
    disableButtons: false
  },
  model: {
    prop: 'datePickerValue',
    event: 'updateParentDate'
  },
  data() {
    return {
      maximumDate: dayjs.utc().format('YYYY-MM'),
      menuOpen: false
    };
  },
  methods: {
    resetDate: function () {
      this.updateDate(this.maximumDate);
    },
    clickNextMonth: function () {
      const requestedDate = dayjs.utc(this.datePickerValue, 'YYYY-MM').add(1, 'months');

      if (requestedDate <= dayjs.utc(this.maximumDate, 'YYYY-MM')) {
        this.updateDate(requestedDate.format('YYYY-MM'));
      }
    },
    clickPreviousMonth: function () {
      this.updateDate(dayjs.utc(this.datePickerValue, 'YYYY-MM').subtract(1, 'months').format('YYYY-MM'));
    },
    updateDatePicker: function (newDate) {
      this.menuOpen = false;
      this.updateDate(newDate);
    },
    updateDate: function (newDate) {
      this.$emit('updateParentDate', newDate);
    }
  },
  mounted () {
    this.updateDate(this.datePickerValue);
  }
};